@import '../../styles/variables';
@import '../../styles/mixins.scss';

.arrow {
	box-shadow: 0 0 12px rgba(28, 28, 30, 0.08);
	border-radius: 50%;
	background-color: $color-white;
	display: flex;
	align-items: center;
	justify-content: center;

	&:hover {
		.icon {
			color: $color-black;
		}
	}

	&_size {
		&_medium {
			@include size(40px);

			.icon {
				font-size: 20px;
			}
		}
	}

	&_size {
		&_small {
			@include size(24px);
			line-height: 24px;

			.icon {
				font-size: 14px;
			}
		}
	}

	&_direction_left {
		.icon {
			transform: rotate(90deg);
		}
	}

	&_direction_right {
		.icon {
			transform: rotate(-90deg);
		}
	}
}

.icon {
	color: $color-black-60;
	position: relative;
	transition: color 0.3s;
}
