@import './grid.scss';
@import './fonts.scss';
@import './variables.scss';
@import './modal.scss';
@import './simplebar.scss';
@import './toastify.scss';
@import './table.scss';
@import './phone-input.scss';

:root {
	--scroll-width: 0px;
}

* {
	outline: 0;
	box-sizing: border-box;
	-webkit-tap-highlight-color: transparent;
}

body {
	font-family: 'Inter', sans-serif;
	font-weight: 400;
	font-size: 14px;
	line-height: 1.4em;
	margin: 0;
	color: $color-black;
	width: 100%;
	//scroll-behavior: smooth;
	-webkit-font-smoothing: antialiased;
	min-width: 375px;
	padding-right: var(--scroll-width);
}
//
//:root.hidden {
//	overflow: hidden;
//	touch-action: none;
//	-ms-touch-action: none;
//
//	body {
//		overflow: hidden;
//		touch-action: none;
//		-ms-touch-action: none;
//		#__next {
//			overflow: hidden;
//			touch-action: none;
//			-ms-touch-action: none;
//		}
//	}
//}
#__next {
	display: flex;
	flex-direction: column;
	flex: 1;
}

img {
	max-width: 100%;
	height: auto;
}

p {
	margin: 10px 0;
}

button,
input,
select,
textarea {
	font-family: 'Inter', sans-serif;
	color: $color-black;
	font-size: 14px;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
}

textarea::placeholder {
	color: $color-gray-light;
}

a {
	text-decoration: none;
	color: $color-black;

	&:hover,
	&:active {
		color: $color-black-92;
	}
}

b {
	font-weight: 500;
}

button {
	margin: 0;
}

.hide-scroll {
	@include hide-scroll;
}

.position-relative {
	position: relative;
}

.pb-30 {
	padding-bottom: 30px;
}

.slick-slider {
	max-width: 100%;
}

.button_link {
	background-color: transparent;
	padding: 0;
	border: 0;
	color: $color-blue;
	transition: color 0.2s;
	cursor: pointer;

	&:hover {
		color: $color-blue-dark;
	}
}

.no-wrap {
	white-space: nowrap;
}

.grecaptcha-badge {
	display: none !important;
}

.icon-more.more__dots {
	font-size: 18px;
	line-height: 18px;
}

.slick-track {
	margin-left: initial;
}

.slick-slider {
	width: 100%;
	display: grid;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active {
	-webkit-box-shadow: 0 0 0 1000px $color-white inset;
}

.webchat-icon {
	display: none !important;
}

.webchat {
	@include respond-above(md) {
		right: 170px !important;
	}

	z-index: 7001 !important;
}
