$breakpoints: (
	xxs: 375px,
	xs: 576px,
	sm: 768px,
	md: 992px,
	lg: 1200px,
	xl: 1400px,
	xxl: 1800px,
);

@mixin respond-above($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		$breakpoint-value: map-get($breakpoints, $breakpoint);
		@media (min-width: $breakpoint-value) {
			@content;
		}
	} @else {
		@warn 'Invalid breakpoint: #{$breakpoint}.';
	}
}

@mixin respond-below($breakpoint) {
	@if map-has-key($breakpoints, $breakpoint) {
		$breakpoint-value: map-get($breakpoints, $breakpoint);
		@media (max-width: ($breakpoint-value - 1)) {
			@content;
		}
	} @else {
		@warn 'Invalid breakpoint: #{$breakpoint}.';
	}
}

@mixin respond-between($lower, $upper) {
	@if map-has-key($breakpoints, $lower) and map-has-key($breakpoints, $upper) {
		$lower-breakpoint: map-get($breakpoints, $lower);
		$upper-breakpoint: map-get($breakpoints, $upper);
		@media (min-width: $lower-breakpoint) and (max-width: ($upper-breakpoint - 1)) {
			@content;
		}
	} @else {
		@if (map-has-key($breakpoints, $lower) == false) {
			@warn 'Your lower breakpoint was invalid: #{$lower}.';
		}
		@if (map-has-key($breakpoints, $upper) == false) {
			@warn 'Your upper breakpoint was invalid: #{$upper}.';
		}
	}
}

@mixin hide-scroll {
	scrollbar-width: none;
	-ms-overflow-style: none;

	&::-webkit-scrollbar {
		display: none;
	}
}

@mixin top-shadow {
	position: relative;

	&:before {
		content: '';
		position: absolute;
		left: 5%;
		right: 5%;
		top: 0;
		height: 5px;
		box-shadow: 2px 0 12px -1px rgba(0, 0, 0, 0.5);
		border-radius: 50%;
	}

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 20px;
		background-color: #ffffff;
		pointer-events: none;
	}
}

@mixin left-shadow {
	position: relative;

	&:before {
		content: '';
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: 5px;
		box-shadow: 0 2px 15px -1px rgba(0, 0, 0, 0.25);
		border-radius: 50%;
	}

	&:after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		width: 24px;
		background-color: #ffffff;
		pointer-events: none;
	}
}

@mixin bottom-shadow {
	position: relative;
	z-index: 0;

	&:before {
		content: '';
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		height: 5px;
		box-shadow: -2px 0 15px -1px rgba(0, 0, 0, 0.25);
		border-radius: 50%;
	}

	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		height: 20px;
		background-color: #ffffff;
		pointer-events: none;
	}
}

@mixin size($size) {
	min-width: $size;
	max-width: $size;
	width: $size;
	height: $size;
	min-height: $size;
	max-height: $size;
}

@mixin hover-image {
	transform: scale(1);
	transition: transform 0.3s;
	&:hover {
		transform: scale(1.1);
	}
}

@mixin hover {
	padding: 16px;
	border-radius: 4px;
	&:hover {
		box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
	}
}

@mixin green-link {
	a {
		background-color: transparent;
		padding: 0;
		border: 0;
		color: $color-green;
		cursor: pointer;
		&:hover {
			color: $color-green-dark;
		}
	}
}

@mixin grey-link {
	a {
		background-color: transparent;
		padding: 0;
		border: 0;
		color: $color-black-60;
		cursor: pointer;
		text-decoration: underline;
	}
}
