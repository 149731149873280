@import "../../styles/mixins.scss";
@import "../../styles/variables.scss";

.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
}

.container {
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  @include respond-below(lg) {
    max-width: 100%;
  }
}

.text {
  margin-top: 24px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  text-align: center;
  color: $color-black-60;

  @include respond-below(md) {
    margin-top: 16px;
    font-size: 13px;
    line-height: 16px;
  }

  a {
    color: #0071E6;
  }
}

.arrow {
  position: absolute;
  left: -24px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  @include respond-below(lg) {
    left: -14px;
  }
  &_right {
    left: revert;
    right: -24px;
    @include respond-below(lg) {
      right: -14px;
    }
  }
}

.wrapper_single {
  display: grid;
  gap: 16px;
  grid-auto-flow: column;
  margin: 0 -16px;

  @include respond-above(md) {
    overflow: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  @include respond-below(md) {
    gap: 8px;
    padding: 0 20px;
  }
}

.draw_winners_wrapper {
  position: relative;
}

.gradient {
  position: absolute;
  top: 0;
  width: 100px;
  height: 132px;
}

.gradient_left {
  left: -20px;
  background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
}

.gradient_right {
  right: -20px;
  background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 100%);
}

.wrapper_double,
.wrapper_single {
  @include respond-below(md) {
    @include hide-scroll;
    overflow-x: scroll;
  }
}

.noneOverflow {
  @include respond-below(lg) {
    overflow: inherit !important;
  }
}

.block {
  width: 100%;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  background: #ffffff;
  margin: 48px 0 92px;
  align-items: center;

  @include respond-below(lg) {
    padding: 0;
    margin: 24px 0 56px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__name {
    font-style: normal;
    font-weight: 600;
    font-size: 40px;
    line-height: 46px;
    color: $color-black;
    text-align: center;

    @include respond-below(md) {
      font-size: 28px;
      line-height: 34px;
    }
  }

  &__body {
    margin-top: 40px;
    width: 100%;

    @include respond-below(md) {
      margin-top: 32px;
    }
  }
}

.small {
  max-width: 880px;
}

.card {
  background: #ffffff;
  border-radius: 16px;
  padding: 12px 16px;
  margin: auto 0;
  height: 100%;
  width: 324px;
  border: 1px solid $color-gray-4;

  @include respond-below(md) {
    width: 280px;
  }

  &__superDraws {
    position: relative;
    z-index: 1;

    @include respond-below(md) {
      width: 320px;
    }
  }

  &__winner {
    box-shadow: none;
    cursor: pointer;

    &::after {
      background: #f4f4f5;
    }
  }

  &__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 12px;
    flex-wrap: wrap;

    &__left {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 21px;
      color: #1c1c1e;
      flex-shrink: 0;
      margin-right: 5px;

      @include respond-below(md) {
        font-size: 15px;
        line-height: 20px;
      }
    }

    &__rigth {
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #757576;

      @include respond-below(sm) {
        font-size: 13px;
        line-height: 16px;
      }

      &__blue {
        font-weight: 500;
        font-size: 15px;
        line-height: 20px;
        color: #0071E6;
        display: flex;
        align-items: center;

        @include respond-below(md) {
          font-size: 14px;
          line-height: 18px;
        }

        i {
          font-size: 12px;
          margin-left: 2px;
          transform: rotate(-90deg);
        }
      }
    }
  }
  &__body {
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    color: #757576;
    display: flex;
    flex-direction: column;

    @include respond-below(md) {
      font-size: 14px;
      line-height: 18px;
    }

    span {
      margin-top: 4px;
      display: flex;
      flex-shrink: 0;
      white-space: nowrap;
    }

    &__super {
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;

      @include respond-below(sm) {
        font-size: 13px;
        line-height: 16px;
      }
    }
  }
}

.padding {
  padding: 32px 14px;

  @include respond-below(md) {
    padding: 20px 0;
  }
}
.expanderWrap {
  [class*="block__body"] {
    flex-direction: column;
  }
}

.expander {
  width: 100%;
  background: $color-white;
  padding: 10px 0;

  &_margin {
    margin-bottom: 0;
  }
  i {
    color: $color-gray-1;
    transition: none;
  }
  &::after,
  &::before {
    content: none;
  }
  span {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: $color-black;

    @include respond-below(md) {
      font-size: 15px;
      line-height: 20px;
    }
  }
  [class*="expander__header"] {
    [class*="expander__icon_expand"] {
      color: $color-black;
    }
    &:hover {
      i {
        color: $color-black;
      }
      span {
        color: $color-black;
      }
    }
  }
  &__desc {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    color: $color-black;

    @include respond-below(md) {
      font-size: 15px;
      line-height: 20px;
    }

    ul {
      margin: 0;
      padding: 0;
      padding-inline-start: 30px;
      margin-block: 0;
    }

    a,
    span {
      color: #0a84ff;
      cursor: pointer;
    }
  }
}

.prizesTitle {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  padding-top: 16px;
  position: relative;
  @media (max-width: 800px) {
    border-top: 1px solid #ececf0;
    padding-top: 24px;
  }

  &__icon {
    width: 64px;
    position: absolute;
    margin: -13px 0px 0px -275px;
    @include respond-below(sm) {
      margin: -21px 0px 0px -258px;
    }
  }

  &__text {
    color: #1c1c1e;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px; /* 131.25% */
  }
}

.prizes {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
  flex-wrap: wrap;

  @include respond-below(lg) {
    justify-content: center;
  }

  @include respond-below(xs) {
    flex-direction: column;
    align-items: center;
  }

  &_text {
    margin: 0 auto;
  }

  &_text1 {
    max-width: 146px;
  }

  &_text2 {
    max-width: 166px;
  }

  &_text3 {
    max-width: 173px;
  }

  &_main {
    display: block;
    max-width: 540px;
    margin: 0 auto;

    @include respond-below(xs) {
      max-width: 284px;
    }
  }

  &_money {
    margin-top: 40px;
    display: flex;
    align-items: center;
    gap: 24px;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    line-height: 21px;
    color: $color-black-60;
    max-width: 578px;

    @include respond-below(md) {
      margin-top: 32px;
      font-size: 15px;
      line-height: 20px;
    }

    @include respond-below(xs) {
      flex-direction: column;
      gap: 32px;
    }

    &_right {
      display: flex;
      flex-direction: column;
      gap: 24px;

      @include respond-below(xs) {
        max-width: 162px;
      }
    }

    &_left {
      @include respond-below(xs) {
        max-width: 172px;
      }
    }
  }

  &_cars {
    display: block;
    max-width: 710px;
    margin: -38px auto 0;

    @include respond-below(md) {
      max-width: 480px;
      margin: -16px auto 0;
    }

    @include respond-below(xs) {
      max-width: 100%;
    }
  }

  &_plus {
    display: block;
    width: 64px;
    height: 64px;
    margin: 40px auto;

    @include respond-below(md) {
      margin: 32px auto 24px;
      width: 48px;
      height: 48px;
    }
  }

  &_cash {
    display: flex;
    justify-content: center;
    gap: 40px;

    img {
      width: 200px;
      height: 200px;

      @include respond-below(md) {
        width: 150px;
        height: 150px;
      }
    }

    @include respond-below(md) {
      gap: 32px;
    }

    @include respond-below(xs) {
      flex-direction: column;
      gap: 24px;
    }
  }

  &_img {
    margin-bottom: 8px;
  }

  &_card {
    display: flex;
    flex-direction: column;
    gap: 4px;
    flex: 1;
    align-items: center;

    @include respond-below(lg) {
      flex: initial;
    }

    @media (max-width: 800px) {
      flex: 0 0 calc(50% - 16px);
    }

    @include respond-below(sm) {
      gap: 6px;
    }
  }

  &_description {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: $color-black-60;

    @include respond-below(md) {
      font-size: 15px;
      line-height: 20px;
    }
  }

  &_title {
    font-size: 28px;
    font-weight: 600;
    line-height: 34px;

    @include respond-below(md) {
      font-size: 24px;
      line-height: 28px;
    }
  }

  &_amount {
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;

    @include respond-below(md) {
      font-size: 14px;
      line-height: 18px;
    }

    @include respond-below(sm) {
      margin-top: -6px;
    }
  }
}

.divider {
  @include respond-above(xs) {
    display: none;
  }

  @include respond-below(xs) {
    width: 200px;
    border-bottom: 1px solid $color-gray-4;
  }
}

.limited {
  max-width: 176px;
}

.brands {
  a {
    &:nth-child(5n) {
      .brands__card {
        margin-right: 0 !important;
      }
    }
  }

  &__block {
    display: grid;
    column-gap: 16px;
    grid-template-columns: repeat(5, 228px);
    padding: 0 16px;
    margin: 0 -16px;
    overflow-x: auto;
    @include hide-scroll;

    &::-webkit-scrollbar {
      width: 0;
    }

    @include respond-below(md) {
      padding: 0 20px;
      grid-auto-flow: column;
      column-gap: 8px;
      gap: 8px;
      grid-template-rows: repeat(2, 88px);
    }
  }

  &__card {
    flex: 1;
    background: #ffffff;
    border: 1px solid $color-gray-4;
    border-radius: 16px;
    margin-right: 0;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 104px;

    &:hover {
      border: 1px solid $color-gray-3;
    }

    @include respond-below(md) {
      height: 88px;
      width: 120px;
    }

    @include respond-below(sm) {
      margin-bottom: 8px;
    }

    img {
      width: 80px;

      @include respond-below(md) {
        width: 64px;
      }
    }
  }
  &__link {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: #757576;
    margin: 0 auto;
    padding-top: 16px;
    display: flex;
    width: fit-content;

    :hover {
      color: $color-black;
    }

    @include respond-below(md) {
      margin-top: 0;
      font-size: 15px;
      line-height: 20px;
    }
  }
}

.blocks_emall {
  @include respond-below(md) {
    grid-template-columns: repeat(17, 120px);
  }
}

.blocks_edostavka {
  @include respond-below(md) {
    grid-template-columns: repeat(18, 120px);
  }
}

.rules {
  display: flex;
  flex-direction: column;

  &__wrapper {
    margin-top: 64px;

    @include respond-below(md) {
      margin-top: 40px;
    }

    @include respond-below(xs) {
      margin-top: 24px;
    }
  }

  &__block {
    display: flex;
    gap: 32px;
    justify-content: space-between;

    @include respond-below(md) {
      flex-direction: column;
      align-items: center;
    }
  }

  &__card {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;

    @include respond-below(md) {
      max-width: initial;
      width: 100%;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &__badge {
      width: 48px;
      height: 48px;
      background: #f4f4f5;
      border-radius: 111px;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: center;

      @include respond-below(sm) {
        width: 40px;
        height: 40px;
        margin-bottom: 12px;
      }

      &::after {
        content: attr(data-number);
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #1c1c1e;

        @include respond-below(sm) {
          font-size: 17px;
          line-height: 22px;
        }
      }
    }

    &__title {
      font-style: normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px;
      text-align: center;
      color: $color-black;
      margin-bottom: 8px;

      @include respond-below(sm) {
        font-size: 17px;
        line-height: 22px;
      }
    }

    &__desc {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 21px;
      text-align: center;
      color: $color-black-60;
      a {
        color: #0a84ff;
        cursor: pointer;
      }
      span {
        color: #0a84ff;
        cursor: pointer;
      }
      @include respond-below(md) {
        font-size: 15px;
        line-height: 20px;
      }
    }
  }

  &__link {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 21px;
    color: $color-black-60;
    display: flex;
    width: fit-content;
    margin: 24px auto 0;

    :hover {
      color: $color-black;
    }

    i {
      margin-right: 8px;
    }

    @include respond-below(md) {
      margin-top: 32px;
      font-size: 15px;
      line-height: 20px;
    }
  }
}

.betterTogether {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -16px;
  text-align: center;
  span {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    color: #1c1c1e;
  }
  img {
    margin-top: 32px;
    height: 40px;
    width: auto;
  }
}

.tabs {
  padding: 0 20px;
  display: flex;
  justify-content: center;
  margin-bottom: 24px;

  @include respond-below(md) {
    margin-bottom: 16px;
  }

  &__emall {
    transition: none;

    &:hover {
      color: #F4303B;
      transition: none;
    }
  }

  &__edostavka {
    transition: none;

    &:hover {
      color: #02B875;
      transition: none;
    }
  }

  &__tab {
    cursor: pointer;
    display: flex;
    width: fit-content;
    height: 42px;
    align-items: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    color: #757576;
    border-bottom: 2px solid white;

    &:first-child {
      margin-right: 16px;
    }

    @include respond-below(md) {
      font-size: 15px;
      line-height: 20px;
    }

    &_active {
      border-bottom: 2px solid #757576;
    }
  }
}

.faq {
  position: relative;

  & > div {
    border-bottom: 1px solid $color-gray-4;

    &:last-child {
      border-bottom: none;
    }
  }
}
