@import '../../styles/variables';
@import '../../styles/mixins';

.load {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 50px;
	height: 50px;
	animation-name: opacityAnimation;

	@include respond-below(sm) {
		width: 44px;
		height: 44px;
	}
}

@keyframes opacityAnimation {
	0% {
		opacity: 0;
	}
	80% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.load span {
	display: inline-block;
	border: 0;
	margin: 0;
	width: 42%;
	height: 42%;
	position: absolute;
	border-radius: 8px;
	animation: spin 2s ease infinite;
}

.load :first-child {
	background: #F4303B;
	animation-delay: -1.5s;
}
.load :nth-child(2) {
	background: #F65962;
	animation-delay: -1s;
}
.load :nth-child(3) {
	background: #F88389;
	animation-delay: -0.5s;
}
.load :last-child {
	background: #FBACB1;
}

@keyframes spin {
	0%,
	100% {
		transform: translate(0);
	}
	25% {
		transform: translate(160%);
	}
	50% {
		transform: translate(160%, 160%);
	}
	75% {
		transform: translate(0, 160%);
	}
}
