@import '../../styles/mixins';
@import '../../styles/variables';

.header {
	@include respond-above(md) {
		&_shadow {
			@include top-shadow;
		}

		text-align: center;
		padding: 55px 0 30px;
		margin: 0 30px;
		font-size: 24px;
		font-weight: 600;
		line-height: 29px;

		&:before {
			top: initial;
			bottom: 0;
		}

		&:after {
			top: initial;
			bottom: -20px;
			height: 25px;
		}
	}

	@include respond-below(md) {
		padding: 26px 46px 24px 46px;
		box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
		position: relative;
		z-index: 1;

		&__title {
			text-align: center;
			display: block;
			font-size: 20px;
			font-weight: 500;
		}
	}

	&__description {
		display: block;
		font-weight: 400;
		font-size: 14px;
		line-height: 20px;
		text-align: center;
		padding: 15px 46px 0;
		color: $color-gray-light;

		@include respond-below(sm) {
			padding: 15px 0 0;
		}
	}
}

.body {
	padding: 30px;
	overflow: auto;

	@include respond-below(md) {
		padding: 30px 20px;
	}
}

.loader {
	display: flex;
	align-items: center;
	justify-content: center;
	flex: 1;
	min-height: 400px;
}

.swiper {
	touch-action: auto;
	position: absolute;
	z-index: 1000;
	top: 0;
	left: 0;
	right: 0;
	height: 48px;

	@include respond-above(md) {
		display: none;
	}
}

.actions {
	// display: flex;
	margin-top: 30px;
	z-index: 1;

	&_buttons {
		display: flex;
	}

	&_error {
		margin-bottom: 21px;
	}

	&__button_disabled {
		background-color: $color-gray-stroke !important;
	}

	@include respond-below(md) {
		margin: 30px -20px -30px;

		&__button {
			border-radius: 0;
			flex: 1;
			height: 60px;

			&:first-child {
				border-top-left-radius: 4px;
				border-color: $color-gray-stroke;
				font-weight: 500;

				&:hover {
					border-width: 2px;
				}
			}

			&:last-child {
				border-top-right-radius: 4px;
			}
		}
	}

	&_short & {
		@include respond-below(md) {
			&__button {
				border-radius: 4px 4px 0 0;
				&:first-child {
					display: none;
				}
			}
		}
	}

	&_full & {
		@include respond-above(md) {
			&__button {
				&:first-child {
					margin-right: 14px;
				}
			}
		}
	}
	&_center {
		@include respond-above(md) {
			@include top-shadow;
			margin-top: 0;
			padding: 30px 30px 36px 30px;
			flex-direction: row-reverse;
			justify-content: flex-end;
			background: white;
			position: sticky;
			bottom: 0;
		}
		@include respond-below(md) {
			position: sticky;
			bottom: 0;
			margin: 0;    
			margin-top: auto;
		}
	}
	&_center & {
		@include respond-below(md) {
			&__button {
				border-radius: 4px 4px 0 0;
				width: auto;
				margin: 0;
				z-index: 1;
				&:first-child {
					display: none;
				}
			}
		}
	}
	&_center & {
		@include respond-above(md) {
			&__button {
				width: auto;
				flex: 1;
				&_cancel {
					background: #f9f9f9;
					color: black;
					font-weight: 600;
					font-size: 16px;
					margin: 0;
					margin-right: 8px;
					border: 0;
				}

				&_accept {
					margin-left: 8px;
					min-width: 180px;
				}
			}
		}
	}

	&_left {
		@include respond-above(md) {
			@include top-shadow;
			margin-top: 24px;
			padding-top: 30px;
			flex-direction: row-reverse;
			justify-content: flex-end;
		}
	}

	&_left & {
		@include respond-below(md) {
			&__button {
				border-radius: 4px 4px 0 0;
				position: fixed;
				bottom: 0;
				left: 0;
				right: 0;
				margin: 0;
				z-index: 1;
				&:first-child {
					display: none;
				}
			}
		}
	}

	&_single {
		@include respond-below(md) {
			position: fixed;
			bottom: 0;
			left: 0;
			right: 0;
			margin: 0;
		}
	}

	&_left & {
		@include respond-above(md) {
			&__button {
				width: auto;
				margin-right: 20px;

				&_cancel {
					border: 0;
					font-weight: 400;
					color: $color-gray-light;
				}

				&_accept {
					min-width: 180px;
				}
			}
		}
	}
}

.title {
	margin: 0;
	font-size: 18px;
	font-weight: 500;
	line-height: 22px;
}

.close {
	position: absolute;
	top: 0;
	right: 0;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 20px;
	cursor: pointer;
	background-color: transparent;
	z-index: 8000;
	line-height: 26px;
	color: #9a9da2;
	@include respond-below(md){
		padding: 16px;
	}
}
