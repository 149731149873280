@import "../../styles/variables.scss";
@import "../../styles/mixins.scss";

.footer {
  z-index: 4000;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 16px;

  @include respond-below(md) {
    margin-top: 32px;
    padding: 0 16px;
  }

  &__image {
    position: absolute;
    left: -242px;
    bottom: -16px;

    @media (max-width: 1356px) {
      max-width: 188px;
      left: -184px;
    }

    @media (max-width: 1250px) {
      max-width: 164px;
      left: -158px;
    }

    @media (max-width: 1200px) {
     display: none;
    }
  }

  &__wrapper {
    position: relative;
    max-width: 880px;
  }

  &__copyright {
    white-space: pre-wrap;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #b5b5b8;

    @include respond-below(md) {
      flex-direction: column;
      border-bottom: none;
    }
  }

  &__results {
    max-width: 1200px;
    margin-bottom: 12px;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: #1c1c1e;
    align-self: flex-start;

    a:hover {
      color: $color-black-60;
    }

    i {
      font-size: 12px;
      transform: rotate(-90deg);
    }
  }
}
