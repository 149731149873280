@import "./variables";

.table-responsive {
  overflow: auto;
}

.table {
  width: 100%;
  border-collapse: collapse;

  th {
    background-color: $color-gray-light-bg;
    padding: 10px 6px;
    text-align: left;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: $color-gray;
  }

  td {
    padding: 20px 6px;
    border-bottom: 1px solid $color-gray-stroke;
  }
}

