@import "./mixins";
@import "./variables";

.react-responsive-modal-root {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 8900;
}

.react-responsive-modal-overlay {
  background: rgba(54, 59, 69, 0.25);
  //backdrop-filter: blur(6px);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: -1;
}

.react-responsive-modal-container {
  touch-action: none;
  height: 100%;
  outline: 0;
  overflow-x: hidden;
  overflow-y: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  @include respond-below(sm) {
    align-items: flex-end;
  }
}

/* Used to trick the browser to center the modal content properly  */
.react-responsive-modal-containerCenter:after {
  width: 0;
  height: 100%;
  content: "";
  display: inline-block;
  vertical-align: middle;
}

.react-responsive-modal-modal {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
  background: #ffffff;
  box-shadow: 0 8px 50px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  padding: 20px;
  position: relative;
  overflow-y: auto;
  width: 500px;
  max-width: 100%;
  max-height: 90%;
  will-change: opacity;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    width: 6px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #ececec;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $color-gray-stroke;
    border-radius: 3px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $color-gray-light;
  }
}

.react-responsive-modal-modal.swiping {
  @include respond-below(md) {
    will-change: transform;
    //touch-action: none;
    position: fixed;
    bottom: 0;
    width: 100%;
    transform: translateY(0);
    border-radius: 16px 16px 0 0;
    padding: 48px 20px 30px;
    [class*="error_error_wrap"] {
      margin: 0 20px;
    }

    // &:before {
    // 	content: '';
    // 	height: 4px;
    // 	width: 100px;
    // 	border-radius: 4px;
    // 	background-color: $color-gray-stroke;
    // 	display: block;
    // 	position: absolute;
    // 	top: 14px;
    // 	left: calc(50% - 50px);
    // }
  }
}

.react-responsive-modal-modal.fullscreen {
  padding: 0;

  .react-responsive-modal-closeButton {
    @include respond-below(md) {
      padding: 22px 10px;
      display: flex;
    }
  }

  @include respond-below(md) {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    bottom: 0;
    width: 100%;
    border-radius: 0;
    max-height: initial;
    padding: 0;
    [class*="error_error_wrap"] {
      margin: 0 20px;
    }
    &:before {
      display: none;
    }
  }
}

.react-responsive-modal-closeButton {
  position: absolute;
  top: 0;
  right: 0;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  cursor: pointer;
  background-color: transparent;
  z-index: 8000;
  line-height: 26px;

  @include respond-below(md) {
    // display: none;
    padding: 16px;
  }

  svg {
    width: 26px;
    height: 26px;
    fill: $color-gray-light;
  }

  &.circled {
    background-color: $color-white;
    border-radius: 50%;
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@keyframes react-responsive-modal-overlay-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes react-responsive-modal-overlay-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@include respond-above(md) {
  @keyframes react-responsive-modal-modal-in {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes react-responsive-modal-modal-out {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
}

@include respond-below(md) {
  @keyframes react-responsive-modal-modal-in {
    0% {
      opacity: 0;
      transform: translateY(100%);
    }
    50% {
      opacity: 0;
      transform: translateY(50%);
    }
    100% {
      opacity: 1;
      transform: translateY(0%);
    }
  }

  @keyframes react-responsive-modal-modal-out {
    0% {
      opacity: 1;
      transform: translateY(0%);
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateY(100%);
    }
  }
}

.react-responsive-modal-modal.default {
  padding: 56px 32px;
  width: 444px;

  @include respond-below(md) {
    padding: 40px 24px;

    @keyframes react-responsive-modal-modal-in {
      0% {
        opacity: 0;
      }
      100% {
        opacity: 1;
      }
    }

    @keyframes react-responsive-modal-modal-out {
      0% {
        opacity: 1;
      }
      100% {
        opacity: 0;
      }
    }
  }

  @include respond-below(sm) {
    max-width: calc(100% - 40px);
  }
}
