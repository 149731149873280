@import '../../styles/variables.scss';
@import '../../styles/mixins';

.error {
	&_wrap {
		background: rgba(253, 87, 66, 0.06);
		border-radius: 4px;
		padding: 14px 15px;
		display: flex;

		&_icon {
			width: 18px;
			height: 18px;
			overflow: hidden;
			border-radius: 50%;
			display: inline-block;
			margin-right: 12.83px;

			@include respond-below(md) {
				margin-right: 12.83px !important;
				margin-left: 0 !important;
			}

			[class*='icon icon-warning'] {
				position: absolute;
				z-index: 1;
				border-radius: 50%;
				color: $color-white;
				background: $color-red;
			}
		}

		&_title {
			font-weight: 600;
			display: block;
			@include respond-below(md) {
				margin-right: 0 !important;
				margin-left: 0 !important;
			}
		}

		&_description {
			font-size: 12px;
			color: $color-gray-light;
			@include respond-below(md) {
				margin-right: 0 !important;
				margin-left: 0 !important;
			}
		}
	}
}

.heading {
	padding-bottom: 6px;
	display: flex;
	align-items: center;
}

.title {
	color: $color-red;
	font-weight: 500;
	margin-top: 2px;
}

.icon {
	color: $color-red;
	font-size: 18px;
	margin-right: 6px;
}

.text {
	font-size: 12px;
	font-style: italic;
	line-height: 15px;
	color: $color-red-lighter;

	a {
		font-weight: bold;
		color: $color-red-lighter;
	}
}
